<template>
  <div>
    <!-- contact.html 12-33 -->
    <!--面包屑导航-->
    <div class="breadcrumb">
      <div class="container">
        <h2>
          <a href>首页</a>&gt;
          <a href>产品中心</a>
        </h2>
      </div>
    </div>
    <!--页面主体-->
    <div class="main container">
      <div class="contect">
        <h2></h2>
        <h3>
          全国服务电话：4000-585-116(电商经销) 4008-508-581(线下代理) 周一至周日9:00-18:00(仅收市话费)
          <br />金牌售后客服：15601141120
        </h3>
        <h4>净美仕环境科技有限公司</h4>
        <p>
          地 址：北京市朝阳区红军营南路15号瑞普大厦C座1102
          <br />邮 箱：yuanquan@mfresh.cn
          <br />电 话：86-10-57422471
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>